import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell beix grisenc de fins a 2,5 cm de diàmetre una mica enfonsat al centre per agafar finalment forma d’embut. Les làmines són de color blanc amb moltes lamel·les atapeïdes i les espores són blanques en massa, llises, el·líptiques, de 5,5-6,5 x 2,5-3,5 micres. El peu no gaire llarg, és més gruixut a la part inferior i és del mateix color que el capell, però més blanquinós a la base.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      